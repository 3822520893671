import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagingData, ShopingCartBackEnd } from '../models';
import { AmmShoppingCartBackEnd } from '../models/catalog.model';
import { MODULE_SERVICE_NAME } from './../constants/app.constant';
import { PolarJService } from './polarj.service';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  private _polarjService: PolarJService = inject(PolarJService);
  updateShoppingCart(shoppingCarts: ShopingCartBackEnd) {
    return this._polarjService.postCall({
      moduleServiceName: 'shoppingcart',
      modelName: 'thecart',
      requestMappingString: 'updateCart',
      para: shoppingCarts,
      pageData: true,
    });
  }

  getShoppingCart(): Observable<PagingData<AmmShoppingCartBackEnd>> {
    return this._polarjService.getCall({
      moduleServiceName: MODULE_SERVICE_NAME.SHOPPINGCART,
      modelName: 'thecart',
      pageData: true,
    });
  }
}
