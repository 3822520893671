import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { Observable, Subject } from 'rxjs';
import { RbacService } from './rbac.service';
import { StorageService } from './storage.service';

export interface UserInfo {
  info: {
    sub: string;
    email: string;
    given_name: string;
    family_name: string;
    picture: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class GoogleApiService {
  gmail = 'https://gmail.googleapis.com';
  token: string = '';
  userProfileSubject = new Subject<UserInfo>();
  authCodeFlowConfig: AuthConfig = {
    // Url of the Identity Provider
    issuer: 'https://accounts.google.com',

    // strict discovery document disallows urls which not start with issuers url
    strictDiscoveryDocumentValidation: false,

    // URL of the SPA to redirect the user to after login
    redirectUri: '',
    logoutUrl: '',
    // The SPA's id. The SPA is registerd with this id at the auth-server
    // clientId: '360412492168-rnrkem7cua7ft7u1nf5isn96jp5ibbvj.apps.googleusercontent.com',
    // clientId: 709126461260-s90h1mfln5bms2t51pmmq9ar3588j4lp.apps.googleusercontent.com (howie)
    clientId:
      '360412492168-rnrkem7cua7ft7u1nf5isn96jp5ibbvj.apps.googleusercontent.com',

    // set the scope for the permissions the client should request
    // scope: 'https://www.googleapis.com/auth/gmail.readonly',
    scope: 'openid profile email',
    showDebugInformation: true,
  };
  private _storageService = inject(StorageService);
  constructor(
    private readonly oAuthService: OAuthService,
    private readonly httpClient: HttpClient,
    private _rbacService: RbacService,
  ) {}

  public initGoogleAPI(window: any) {
    this.authCodeFlowConfig.redirectUri = window?.location.origin;
    this.authCodeFlowConfig.logoutUrl = window?.location.origin;

    // confiure oauth2 service
    this.oAuthService.configure(this.authCodeFlowConfig);
    // manually configure a logout url, because googles discovery document does not provide it
    this.oAuthService.logoutUrl = 'https://www.google.com/accounts/Logout';

    // loading the discovery document from google, which contains all relevant URL for
    // the OAuth flow, e.g. login url
    this.oAuthService.loadDiscoveryDocument().then(() => {
      this.oAuthService.tryLoginImplicitFlow().then(() => {
        // when not logged in, redirect to google for login
        // else load user profile
        if (this.oAuthService.hasValidAccessToken()) {
          this.oAuthService.loadUserProfile().then((userProfile) => {
            this.userProfileSubject.next(userProfile as UserInfo);
          });
        }
      });
    });
  }

  emails(userId: string): Observable<any> {
    return this.httpClient.get(
      `${this.gmail}/gmail/v1/users/${userId}/messages`,
      { headers: this.authHeader() },
    );
  }

  getMail(userId: string, mailId: string): Observable<any> {
    return this.httpClient.get(
      `${this.gmail}/gmail/v1/users/${userId}/messages/${mailId}`,
      { headers: this.authHeader() },
    );
  }
  signin() {
    this.oAuthService.initLoginFlow();
    this.token = this.oAuthService.getIdToken();
    this._rbacService.googleLogin(this.token).subscribe((user) => {
      if (user) {
        this._storageService.setLocalItem('buyerInfo', JSON.stringify(user));
      }
    });
  }

  isLoggedIn(): boolean {
    var is_loggedin: boolean = this.oAuthService.hasValidAccessToken();

    if (this.token == '') {
      this.token = this.oAuthService.getAccessToken();

      console.log(this.token);
    }

    return is_loggedin;
  }
  signout() {
    console.log('before signout');
    this.token = '';
    this.oAuthService.logOut();

    console.log('after signout');
  }

  private authHeader(): HttpHeaders {
    var token = this.oAuthService.getAccessToken();

    console.log(token);
    return new HttpHeaders({
      Authorization: `Bearer ${this.oAuthService.getAccessToken()}`,
    });
  }
}
