import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mockProducts } from '../mockData';
import { Product } from '../models/catalog.model';
import { ProductModel } from '../models/ProductModel';
import { PolarJService } from './polarj.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private _polarjService: PolarJService = inject(PolarJService);

  getAllMockedProducts(): Observable<ProductModel[] | undefined> {
    return of(mockProducts);
  }
  getAllProducts(currentPageIndex: number, pageSize: number = 32) {
    return this._polarjService.postCall({
      moduleServiceName: 'catalog',
      modelName: 'ammerchandises',
      requestMappingString: 'quickSearchCombined',
      urlPara: `pageIndex=${currentPageIndex}&pageSize=${pageSize}`,
      pageData: true,
    });
  }

  getProductsByFamilyCode(familyCode: string): Observable<Product[]> {
    return this._polarjService.getCall({
      moduleServiceName: 'catalog',
      modelName: 'ammerchfamilys',
      requestMappingString: `detail/${familyCode}`,
    });
  }
  getAutoComplete(searchKey: string): Observable<Array<string>> {
    return this._polarjService.postCall({
      moduleServiceName: 'catalog',
      modelName: 'amkeywords',
      requestMappingString: 'searchByKey',
      para: searchKey,
      listData: true,
    });
  }
  getFilterCondition(searchKey: string): Observable<any> {
    return this._polarjService.postCall({
      moduleServiceName: 'catalog',
      modelName: 'ammerchandises',
      requestMappingString: 'quickSearchCombined',
      para:searchKey,
      pageData:true,
    });
  }
}
